import Players from './data/players/seriea.json'
import Matches from './data/matches/serieamatches.json'
import LeagueTableHeader from './components/LeagueTableHeader';
import LeagueTable from './components/LeagueTable';
import TableCaption from './components/TableCaption'
import MatchTable from './components/MatchTable'

function SerieATable() {
    return (
        <div className="mt-10">
            <table className="league-table">
                <TableCaption title="Serie A" />
                <tbody className="league-table-body">
                    <LeagueTableHeader />
                    <LeagueTable players={Players} />
                </tbody>
            </table>
            <table className="match-table">
                <TableCaption title="Matches" />
                <tbody className="text-sm text-black-700">
                    <MatchTable matches={Matches} />
                    {/* <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Fraser Hewitt</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Meri Lawes</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Fraser Hewitt</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Tracey Cree</td>
                    </tr>
                    <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Fraser Hewitt</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Phil Thompson</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Fraser Hewitt</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Phil Griffiths</td>
                    </tr>
                    <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Fraser Hewitt</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Alistair Pells</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Meri Lawes</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Tracey Cree</td>
                    </tr>
                    <tr className="match-played-2">
                        <td className="w-40 cell-border-right">Meri Lawes</td>
                        <td className="w-16 cell-border-right">3</td>
                        <td className="w-40 cell-border-right">6-1 6-3</td>
                        <td className="w-16 cell-border-right">1</td>
                        <td className="w-48">Phil Thompson</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Meri Lawes</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Phil Griffiths</td>
                    </tr>
                    <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Meri Lawes</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Alistair Pells</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Tracey Cree</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Phil Thompson</td>
                    </tr>
                    <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Tracey Cree</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Phil Griffiths</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Tracey Cree</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Alistair Pells</td>
                    </tr>
                    <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Phil Thompson</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Phil Griffiths</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Phil Thompson</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Alistair Pells</td>
                    </tr>
                    <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Phil Griffiths</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Alistair Pells</td>
                    </tr> */}
                </tbody>                                                                  
            </table>
        </div>
    )
}

export default SerieATable;