import './App.css';
import ChampionsLeagueTable from './ChampionsLeagueTable';
import PremierLeagueTable from './PremierLeagueTable';
import SerieATable from './SerieATable';
import LaLigaTable from './LaLigaTable';
import MajorLeagueSoccerTable from './MajorLeagueSoccerTable';
import Banner from './Banner';
import Legend from './Legend';

function App() {
  return (
    <div className="App bg-gray-400">
      <Banner />
      <Legend />
      <ChampionsLeagueTable />
      <PremierLeagueTable />
      <SerieATable />
      <LaLigaTable />
      <MajorLeagueSoccerTable />
      <div className="bg-grey-100 h-10"></div>
    </div>
  );
}

export default App;
