import Players from './data/players/majorleague.json'
import Matches from './data/matches/majorleaguematches.json'
import LeagueTableHeader from './components/LeagueTableHeader'
import LeagueTable from './components/LeagueTable'
import TableCaption from './components/TableCaption'
import MatchTable from './components/MatchTable'

function MajorLeagueSoccerTable() {
    return (
        <div className="mt-10">
            <table className="league-table">
                <TableCaption title="Major League Soccer"/>
                <tbody className="league-table-body">
                    <LeagueTableHeader />
                    <LeagueTable players={Players} />
                </tbody>
            </table>
            <table className="match-table">
                <TableCaption title="Matches"/>
                <tbody className="text-sm text-black-700">
                    <MatchTable matches={Matches} />
                    {/* <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Matt Halliday</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Colin Gittins</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Matt Halliday</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Helen Milner</td>
                    </tr>
                    <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Matt Halliday</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Carol Pardoe</td>
                    </tr>
                    <tr className="match-played">
                        <td className="w-40 cell-border-right">Matt Halliday</td>
                        <td className="w-16 cell-border-right">3</td>
                        <td className="w-40 cell-border-right">6-1 6-0</td>
                        <td className="w-16 cell-border-right">1</td>
                        <td className="w-48">Linda Sawyer</td>
                    </tr>
                    <tr className="match-played-2">
                        <td className="w-40 cell-border-right">Matt Halliday</td>
                        <td className="w-16 cell-border-right">3</td>
                        <td className="w-40 cell-border-right">7-5 4-6 1-0</td>
                        <td className="w-16 cell-border-right">2</td>
                        <td className="w-48">Helena Dunning</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Colin Gittins</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Helen Milner</td>
                    </tr>
                    <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Colin Gittins</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Carol Pardoe</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Colin Gittins</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Linda Sawyer</td>
                    </tr>
                    <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Colin Gittins</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Helena Dunning</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Helen Milner</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Carol Pardoe</td>
                    </tr>
                    <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Helen Milner</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Linda Sawyer</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Helen Milner</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Helena Dunning</td>
                    </tr>
                    <tr className="match-played-2">
                        <td className="w-40 cell-border-right">Carol Pardoe</td>
                        <td className="w-16 cell-border-right">3</td>
                        <td className="w-40 cell-border-right">6-4 6-4</td>
                        <td className="w-16 cell-border-right">1</td>
                        <td className="w-48">Linda Sawyer</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Carol Pardoe</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Helena Dunning</td>
                    </tr>
                    <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Linda Sawyer</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Helena Dunning</td>
                    </tr> */}
                </tbody>                                                   
            </table>
        </div>
    )
}

export default MajorLeagueSoccerTable;