function TableCaption(props) {
    return (
        <>
        <caption className="table-caption">
            {props.title}
        </caption>
        </>
    )
}

export default TableCaption