import Players from './data/players/championsleague.json'
import Matches from './data/matches/championsleaguematches.json'
import LeagueTableHeader from './components/LeagueTableHeader'
import LeagueTable from './components/LeagueTable'
import TableCaption from './components/TableCaption'
import MatchTable from './components/MatchTable'

function ChampionsLeagueTable() {
    return (
        <div>
            <table className="league-table">
                <TableCaption title="Champions League" />
                <tbody className="league-table-body">
                    <LeagueTableHeader />
                    <LeagueTable players={Players} />
                </tbody>
            </table>
            <table className="match-table">
                <TableCaption title="Matches" />
                <tbody className="text-sm text-black-700">
                    <MatchTable matches={Matches} />
                </tbody>
            </table>
        </div>
    )
}

export default ChampionsLeagueTable;