import Players from './data/players/laliga.json'
import Matches from './data/matches/laligamatches.json'
import LeagueTableHeader from './components/LeagueTableHeader';
import LeagueTable from './components/LeagueTable';
import TableCaption from './components/TableCaption'
import MatchTable from './components/MatchTable'

function LaLigaTable() {
    return (
        <div className="mt-10">
            <table className="league-table">
                <TableCaption title="La Liga"/>
                <tbody className="league-table-body">
                    <LeagueTableHeader />
                    <LeagueTable players={Players} />
                </tbody>
            </table>
            <table className="match-table">
                <TableCaption title="Matches"/>
                <tbody className="text-sm text-black-700">
                    <MatchTable matches={Matches} />
                    {/* <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Riad Erraji</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Mark Benton</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Riad Erraji</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Luke Roberts</td>
                    </tr>
                    <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Riad Erraji</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Steve Dowling</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Riad Erraji</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Dave Cooney</td>
                    </tr>
                    <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Riad Erraji</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">William Flint</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Riad Erraji</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Cas Jones</td>
                    </tr>
                    <tr className="match-played-2">
                        <td className="w-40 cell-border-right">Mark Benton</td>
                        <td className="w-16 cell-border-right">3</td>
                        <td className="w-40 cell-border-right">7-5 6-4</td>
                        <td className="w-16 cell-border-right">1</td>
                        <td className="w-48">Luke Roberts</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Mark Benton</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Steve Dowling</td>
                    </tr>
                    <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Mark Benton</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Dave Cooney</td>
                    </tr>
                    <tr className="match-played">
                        <td className="w-40 cell-border-right">Mark Benton</td>
                        <td className="w-16 cell-border-right">3</td>
                        <td className="w-40 cell-border-right">6-1 6-4</td>
                        <td className="w-16 cell-border-right">1</td>
                        <td className="w-48">William Flint</td>
                    </tr>
                    <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Mark Benton</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Cas Jones</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Luke Roberts</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Steve Dowling</td>
                    </tr>
                    <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Luke Roberts</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Dave Cooney</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Luke Roberts</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">William Flint</td>
                    </tr>
                    <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Luke Roberts</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Cas Jones</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Steve Dowling</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Dave Cooney</td>
                    </tr>
                    <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">Steve Dowling</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">William Flint</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Steve Dowling</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Cas Jones</td>
                    </tr>
                    <tr className="match-played-2">
                        <td className="w-40 cell-border-right">Dave Cooney</td>
                        <td className="w-16 cell-border-right">3</td>
                        <td className="w-40 cell-border-right">7-6 6-0</td>
                        <td className="w-16 cell-border-right">1</td>
                        <td className="w-48">William Flint</td>
                    </tr>
                    <tr className="h-8 bg-sky-300 border-b-2">
                        <td className="w-40 cell-border-right">Dave Cooney</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Cas Jones</td>
                    </tr>
                    <tr className="h-8 bg-sky-600 border-b-2">
                        <td className="w-40 cell-border-right">William Flint</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-40 cell-border-right">0-0 0-0</td>
                        <td className="w-16 cell-border-right">0</td>
                        <td className="w-48">Cas Jones</td>
                    </tr> */}
                </tbody>                                                                
            </table>
        </div>
    )
}

export default LaLigaTable;