import Players from './data/players/premierleague.json'
import Matches from './data/matches/premierleaguematches.json'
import LeagueTableHeader from './components/LeagueTableHeader'
import LeagueTable from './components/LeagueTable'
import TableCaption from './components/TableCaption'
import MatchTable from './components/MatchTable'

function PremierLeagueTable() {
    return (
        <div className="mt-10">
            <table className="league-table">
                <TableCaption title="Premier League" />
                <tbody className="league-table-body">
                    <LeagueTableHeader />
                    <LeagueTable players={Players} />
                </tbody>
            </table>
            <table className="match-table">
                <TableCaption title="Matches" />
                <tbody className="text-sm text-black-700">
                    <MatchTable matches={Matches} />
                </tbody>                                          
            </table>
        </div>
    )
}

export default PremierLeagueTable;