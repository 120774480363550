function LeagueTable(props) {
    // console.log(props.players.players)
    let players = props.players.players
    players.sort((a,b) => (a.points > b.points) ? -1 : 1)
    return (
        <>
            {
                players.map((player, key) => 
                    <tr className={key % 2 === 0 ? "border-b-2 bg-gray-400" : "border-b-2 bg-gray-300" }>
                        <td className="league-table-name">{player.name}</td>
                        <td className="cell-border-right">{player.played}</td>
                        <td className="cell-border-right">{player.won}</td>
                        <td className="cell-border-right">{player.lost}</td>
                        <td className="cell-border-right">{player.setswon}</td>
                        <td className="cell-border-right">{player.setslost}</td>
                        <td className="cell-border-right">{player.gameswon}</td>
                        <td className="cell-border-right">{player.gameslost}</td>
                        <td className="cell-border-right">{player.points}</td>
                    </tr>
                )
            }
        </>
    )
}

export default LeagueTable 