function MatchTable(props) {
    let matches = props.matches.matches
    return (
        <>
            {
                matches.map((match, key) =>
                    <tr className={match.played === true ? (key % 2 === 1 ? 'match-played' : 'match-played-2') : (key % 2 === 0 ? "h-8 bg-sky-600 border-b-2" : "h-8 bg-sky-300 border-b-2")}>
                        <td className="w-64 cell-border-right">{match.player1}</td>
                        <td className="w-16 cell-border-right">{match.points1}</td>
                        <td className="w-40 cell-border-right">{match.score}</td>
                        <td className="w-16 cell-border-right">{match.points2}</td>
                        <td className="w-64">{match.player2}</td>
                    </tr>
                )
            }
        </>
    )
}

export default MatchTable