function LeagueTableHeader() {
    return (
        <>
            <tr className="h-8 bg-sky-600 border-b-2">
                <td className="league-table-name cell-border-right"></td>
                <td className="league-table-header">Played</td>
                <td className="league-table-header">W</td>
                <td className="league-table-header">L</td>
                <td className="league-table-header">SF</td>
                <td className="league-table-header">SA</td>
                <td className="league-table-header">GF</td>
                <td className="league-table-header">GA</td>
                <td className="league-table-header">Pts</td>
            </tr>
        </>
    )
}

export default LeagueTableHeader