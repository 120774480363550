import BannerImg from './images/wltc-logo.jpeg';

function Banner() {
    return (
        <div className="bg-grey-700 h-50 mb-10">
            <img src={BannerImg} className="h-40 w-40 mx-auto p-2 rounded-3xl" alt="WLTC Box League" />
        </div>
    )
}

export default Banner;